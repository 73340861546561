<template>
	<div class="box">
		<div class="null-page" v-show="yes"></div>
		<el-card class="box-card">
			<div slot="header" class="clearfix">
				<span>编辑上门时间</span>
			</div>

			<div v-loading="loading" class="ns-member-address-list">
				<el-form label-width="80px">
					<el-form-item label="上门时间">
						<el-date-picker
							class="worker_day"
							v-model="worker_day"
							type="date"
							placeholder="选择日期"
							value-format="yyyy-MM-dd"
							:picker-options="pickerOptions0"
							@change="changeWorkerDay">
						</el-date-picker>
						<el-time-picker
							ref="timePicker"
						    is-range
						    v-model="worker_time"
							value-format="HH:mm"
							format="HH:mm"
						    range-separator="至"
						    start-placeholder="开始时间"
						    end-placeholder="结束时间"
						    placeholder="选择时间范围"
							>
						  </el-time-picker>
					</el-form-item>
					<el-form-item>
						<el-button type="primary" size="medium" @click="saveTime()">保存</el-button>
					</el-form-item>
				</el-form>
			</div>
		</el-card>
	</div>
</template>

<script>
	import { workOrderDetail ,modifyAppointTime } from '@/api/order/work_order'
    export default {
        name: "address_edit",
        components: {},
        data() {
            return {
                loading: true,
				yes: true,
				work_order_id:'',
				back:'',
				worker_day:'',
				worker_time:'',
				pickerOptions0: {
					disabledDate(time) {
						return time.getTime() < Date.now() - 8.64e7;//选择今天以及今天之后的日期
					}
				},
            }
        },
        created() {
			if(this.$route.query.work_order_id) this.work_order_id = this.$route.query.work_order_id;
			if(this.$route.query.back) this.back = this.$route.query.back;
			this.getOrderTime();
        },
		mounted() {
			let self = this;
			setTimeout(function() {
				self.yes = false
			}, 300)
		},
        watch: {
        },
        methods: {
			saveTime(){
				var time = '';
				if(this.worker_time) time = this.worker_time[0]+'-'+this.worker_time[1]
				if(!this.worker_day || !time ){
					this.$message.error('请选择上门时间')
					return;
				}
				//检测选中日期是不是当天
				let todayDate=new Date().setHours(0,0,0,0);
				let paramsDate=new Date(this.worker_day).setHours(0,0,0,0);
				let isToday=(todayDate===paramsDate);
				if(isToday){
					//如果是当天  检测开始时间是不是当前时间之后
					if(this.worker_time[0].split(':')[0] < new Date().getHours() || ( ( this.worker_time[0].split(':')[0] == new Date().getHours() ) && this.worker_time[0].split(':')[1] <= new Date().getMinutes() ) ){
						this.$message.error('预约时间不可早于当前时间');
						return;
					}
				}
				console.log(this.worker_day,time)
				modifyAppointTime({
					work_order_id:this.work_order_id,
					appoint_day:this.worker_day,
					appoint_time:time
				})
				.then(res=>{
					if(res.code>=0){
						this.$message('修改成功');
						setTimeout(()=>{
							this.$router.push({path:this.back});
						},500)
					}
				})
				.catch(err=>{})
			},
			changeWorkerDay(e){
				this.$refs.timePicker.focus()
			},
            getOrderTime(){
				workOrderDetail({
					work_order_id:this.work_order_id
				})
				.then(res=>{
					this.worker_day = res.data.appoint_day;
					this.worker_time = res.data.appoint_time.split('-');
					this.loading = false;
				})
				.catch(err=>{
					
				})
			},
		}
    }
</script>
<style lang="scss" scoped>
	.box {
		width: 100%;
		position: relative;
	}
	
	.null-page {
		width: 100%;
		height: 730px;
		background-color: #FFFFFF;
		position: absolute;
		top: 0;
		left: 0;
		z-index: 9;
	}
	
    .el-card.is-always-shadow,
    .el-card.is-hover-shadow:focus,
    .el-card.is-hover-shadow:hover {
        box-shadow: unset;
    }

    .el-card {
        border: 0;
    }

    .ns-len-input {
        width: 350px;
    }

    .el-select {
        margin-right: 10px;
    }
</style>
